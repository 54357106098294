@font-face {
  font-family: "Union";
  src: url("../assets/fonts/union.eot");
  src: url("../assets/fonts/union.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/union.woff2") format("woff2"),
    url("../assets/fonts/union.woff") format("woff"),
    url("../assets/fonts/union.ttf") format("truetype"),
    url("../assets/fonts/union.svg#union") format("svg");
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body,
input {
  background-color: black;
  color: white;
  font-family: "Union", serif;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.5;
}

form {
  text-align: center;
}

label {
  font-size: 2rem;
  display: block;
  margin-bottom: 0.5em;
}

input {
  display: block;
  appearance: none;
  border: none;
  border: 0.0625em solid white;
  text-align: center;
  width: 100%;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 3rem;
}

input:focus {
  outline: none;
}

.Root {
  padding: 1em;
}

.Root,
.Stage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Stage {
  font-size: 3.75rem;
  letter-spacing: 0.03125em;
  text-transform: uppercase;
}

/* Inherit font-size if textFitting is turned on */
.textFitted > .Stage {
  font-size: inherit;
}

.AudiateClick {
  background-color: black !important;
  font-size: 2rem !important;
  line-height: 1.33 !important;
  text-align: center;
}

@media (max-width: 800px) {
  html {
    font-size: 10px;
  }
}
